@font-face {
  font-family: 'HyundaiRegular';
  src: url('../assets/fonts/HyundaiSansHead-Regular.woff2') format('woff2'),
  url('../assets/fonts/HyundaiSansHead-Regular.woff') format('woff'),
  url('../assets/fonts/HyundaiSansHead-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HyundaiMedium';
  src: url('../assets/fonts/HyundaiSansHead-Medium.woff2') format('woff2'),
  url('../assets/fonts/HyundaiSansHead-Medium.woff') format('woff'),
  url('../assets/fonts/HyundaiSansHead-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansArabicRegular';
  src: url('../assets/fonts/NotoSansArabic-Regular.woff2') format('woff2'),
  url('../assets/fonts/NotoSansArabic-Regular.woff') format('woff'),
  url('../assets/fonts/NotoSansArabic-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansArabicMedium';
  src: url('../assets/fonts/NotoSansArabic-Medium.woff2') format('woff2'),
  url('../assets/fonts/NotoSansArabic-Medium.woff') format('woff'),
  url('../assets/fonts/NotoSansArabic-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Droid Arabic Kufi';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/DroidKufi-Regular.ttf') format('truetype');
  font-display: swap
}

@font-face {
  font-family: 'Droid Arabic Kufi';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/DroidKufi-Bold.ttf') format('truetype');
  font-display: swap
}
